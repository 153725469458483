
$(function() {
    'use strict';
    /*jshint devel:true */
    function filterCards(e) {
            var elem = $(e.currentTarget);
            //console.log('elem:' + elem.val());

            if (!elem.val()) {
                cardPaging(cardData);
                renderCardList(cardData);
                return false;
            }
            var filteredCards = {};

            cardData.pagination.page = 1;
            $.extend(filteredCards, cardData);
            filteredCards.filtering = true;

            //console.log('card array', filteredCards.cards);
            filteredCards.cards = $.map(filteredCards.cards, function(card) {
                //console.log('card number', card.cardNumber);

                if (card.cardNumber.toString().indexOf(elem.val()) === 0) {
                    return card;
                }
            });
            //console.log('card data', filteredCards, cardData);

            cardPaging(filteredCards);
            renderCardList(filteredCards);
        }
        //HANDLEBARS HELPERS
        //handlebars helper for pagination
    var paginate = function(pagination, options) {
        var type = options.hash.type || 'middle';
        var ret = '';
        var pageCount = Number(pagination.pageCount);
        var page = Number(pagination.page);
        var limit;
        if (options.hash.limit) limit = +options.hash.limit;

        //page pageCount
        var newContext = {};
        var i = 0;
        switch (type) {
            case 'middle':
                if (typeof limit === 'number') {
                    var leftCount = Math.ceil(limit / 2) - 1;
                    var rightCount = limit - leftCount - 1;
                    if (page + rightCount > pageCount)
                        leftCount = limit - (pageCount - page) - 1;
                    if (page - leftCount < 1)
                        leftCount = page - 1;
                    var start = page - leftCount;

                    while (i < limit && i < pageCount) {
                        newContext = {
                            n: start
                        };
                        if (start === page) newContext.active = true;
                        ret = ret + options.fn(newContext);
                        start++;
                        i++;
                    }
                } else {
                    for (i = 1; i <= pageCount; i++) {
                        newContext = {
                            n: i
                        };
                        if (i === page) newContext.active = true;
                        ret = ret + options.fn(newContext);
                    }
                }
                break;
            case 'previous':
                if (page === 1) {
                    newContext = {
                        disabled: true,
                        n: 1
                    };
                } else {
                    newContext = {
                        n: page - 1
                    };
                }
                ret = ret + options.fn(newContext);
                break;
            case 'next':
                newContext = {};
                if (page === pageCount) {
                    newContext = {
                        disabled: true,
                        n: pageCount
                    };
                } else {
                    newContext = {
                        n: page + 1
                    };
                }
                ret = ret + options.fn(newContext);
                break;
        }

        return ret;
    };

    Handlebars.registerHelper('ifEquals', function(a, b, opts) {
        if (a == b) // Or === depending on your needs
            return opts.fn(this);
        else
            return opts.inverse(this);
    });

    Handlebars.registerHelper('LessThanEquals', function(a, b, opts) {
        if (a <= b) // Or === depending on your needs
            return opts.fn(this);
        else
            return opts.inverse(this);
    });

    Handlebars.registerHelper('GreaterThanEquals', function(a, b, opts) {
        if (a >= b) // Or === depending on your needs
            return opts.fn(this);
        else
            return opts.inverse(this);
    });

    Handlebars.registerHelper('GreaterThan', function(a, b, opts) {
        if (a > b) // Or === depending on your needs
            return opts.fn(this);
        else
            return opts.inverse(this);
    });

    Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
        switch (operator) {
            case '==':
                return (v1 == v2) ? options.fn(this) : options.inverse(this);
            case '!=':
                return (v1 != v2) ? options.fn(this) : options.inverse(this);
            case '===':
                return (v1 === v2) ? options.fn(this) : options.inverse(this);
            case '<':
                return (v1 < v2) ? options.fn(this) : options.inverse(this);
            case '<=':
                return (v1 <= v2) ? options.fn(this) : options.inverse(this);
            case '>':
                return (v1 > v2) ? options.fn(this) : options.inverse(this);
            case '>=':
                return (v1 >= v2) ? options.fn(this) : options.inverse(this);
            case '&&':
                return (v1 && v2) ? options.fn(this) : options.inverse(this);
            case '||':
                return (v1 || v2) ? options.fn(this) : options.inverse(this);
            default:
                return options.inverse(this);
        }
    });

    /* END HANDLEBARS HELPERS*/

    function setUpHandlebarTemplates() {
        if (cardData) {
            var cardsArray = cardData.cards;
            //cardsArray.sort(SortByNotifications);


            Handlebars.registerHelper('paginate', paginate);
            cardPaging(cardData);
            if (cardData.pagination.pageCount <= 1) {
                $('#filterCards').hide();
            } else {
                $('#filterCards').on('keyup', filterCards).on('keyup', function() {
                    $(this).change();
                });
            }

            renderCardList(cardData);
            if (typeof currentCard !== 'undefined' && currentCard !== "null"){
                $('#card-list .indiv-card2[data-id="' + currentCard + '"] .panel-heading.collapsed').trigger({type: "click", setCardId: "false"});
            }
            else if (cardsArray.length == 1){
                $('#card-list .panel-heading.collapsed').click();
            }

        }
    }

    function bindings() {

        $('#cardPagination a').on('click', function(e) {
            e.preventDefault();
            cardData.pagination.page = $(e.currentTarget).data('value');
            renderCardList(cardData);
            //bindings();
        });

        $('#card-list .panel-heading a').on('click', function(e) {
            disabledEventPropagation(e);
        });

        $('#card-list .panel-heading.collapsed').on('click', function(e){
            var panelID = $(this).parent('.panel').data('id');
            if (e.setCardId != "false"){
                setCardID(panelID, getIncompleteJourneys);
            }
            else{
                getIncompleteJourneys(panelID);
            }
//            $('html, body').animate({
//                scrollTop: $("#" + $(this).attr("id")).offset().top
//            }, 500);
        });

        $(".card-collapse a.list-group-item").each(function() {
            var _href = $(this).attr("href");
            $(this).attr("href", _href + qs);
        });

        $(".card-notifications a").each(function() {
            var _href = $(this).attr("href");
            $(this).attr("href", _href + qs);
        });

        $(".card-notifications a").on("click", function(e) {
            e.preventDefault();
            var _self = this;
            var callback = function(){
                followLink(_self.href);
            };
            setCardID($(e.currentTarget).parents(".indiv-card2").data("id"), callback);
        });

        $('#filterCards').bind('keypress keydown keyup', function(e){
           if(e.keyCode == 13) { e.preventDefault(); }
        });
    }

    function followLink(href){
        window.location = href;
    }

    function cardPaging(data) {
        var numOfCards = data.cards.length;
        var pages = Math.ceil(numOfCards / data.pagination.pageSize);
        data.pagination.pageCount = pages;

    }

    function init() {
        if(typeof cardData != 'undefined'){
            setUpHandlebarTemplates();
        }
        $("#errormessage-container").hide();
        if ($("#errormessage").length > 0){
            $("#errormessage-container").show();
        }
        $('#page-content form').attr('novalidate', 'novalidate');
        setPageMenu();
        $('span.font-external').parent('a').attr("target","_blank");
        $('a.external').attr("target","_blank");

        setupSubmitBtn();

        makeDropDownsNiceForOlderDroids();
    }

    function setupSubmitBtn() {
        $('#page-content form input[type-submit], #page-content form button[type="submit"]').not(".no-wait").click(function(e){
            var testCase = $(this).hasClass(".no-wait");
            if(   testCase != true   ){
                e.preventDefault();
                $(this).prop('disabled', 'disabled');
                $(this).text('Please wait...');
                $('#page-content form').find('button[type="submit"], input[type="submit"]').not(".no-wait").prop('disabled', 'disabled');

                var tempElement = $("<input type='hidden'/>");
                var form = $(this).closest("form");
                tempElement.attr("name", this.name).val($(this).val()).appendTo(form);
                form.submit();
            }
        });
    }

    function renderCardList(data) {

        var pagedCards = {};
        $.extend(pagedCards, data);
        var pagination = data.pagination;
        var startNumber = (pagination.page - 1) * pagination.pageSize;
        var endNumber = startNumber + pagination.pageSize;

        pagedCards.cards = $.map(pagedCards.cards, function(card, i) {

            if (i >= startNumber && i < endNumber) {
                return card;
            }
        });
        $('#oyster-card-list').html(cardTemplate(pagedCards));

        bindings();
        //renderIncompleteJourneys(pagedCards);
    }


    function getIncompleteJourneys(cardID){
        $.each(cardData.cards, function(key){
            if (cardData.cards[key].cardNumber != cardID){
                return true;
            }
            if (!cardData.cards[key].incompleteJourney){
                //cardData.cards[key].incompleteJourney = callWebService(cardData.cards[key].cardNumber);
                callIncompleteWebService(cardData.cards[key]);
            }
            else{
                $('.indiv-card2[data-id="'+ cardData.cards[key].cardNumber + '"] .incompleteJourney').html(cardData.cards[key].incompleteJourney);
            }
            //console.log(cardData.cards[key].card);
        });
    }

    function disabledEventPropagation(e)
    {
       if (e.stopPropagation){
           e.stopPropagation();
       }
       else if(window.event){
          window.event.cancelBubble=true;
       }
    }

    function setCardID(cardID, callback){
        $.post("/oyster/selectCard.do?method=ajax", { cardId: cardID })
          .done(function() {
            if(typeof callback == "function")
            callback(cardID);
            //getIncompleteJourneys(cardID);
          });
    }


    function callIncompleteWebService(card){
        $.ajax({
            url: '/oyster/selectCard.do?method=getNotificationsCount',
            success: function(data){
                card.incompleteJourney = data;
                $('.indiv-card2[data-id="'+ card.cardNumber + '"] .incompleteJourney').html(data);
                //return data;
            },
            error: function(){
                //targetElement.innerHTML = '<center>Error while loading notifications. Please try later.</center>';

                card.incompleteJourney = "Error while loading notifications. Please try later.";
                $('.indiv-card2[data-id="'+ card.cardNumber + '"] .incompleteJourney').html("Error while loading notifications. Please try later.");
                //return incompleteJourney;
            }
        });
    }

    function setPageMenu(){
        $("#navOysterCards").click();
        if (typeof selectedNavItem !== 'undefined' && selectedNavItem.length !== 0){
            var elemToSelect = $("#pageNavigation").find('#'+selectedNavItem);
            //var closestCollapse = elemToSelect.closest('.collapse');
            elemToSelect.addClass('selected');
            if (elemToSelect.closest('.collapse').attr("id") != "pageNavigation"){
                elemToSelect.closest('.collapse').addClass('in');
                $('button[data-target="#' + elemToSelect.closest('.collapse').attr("id") + '"]').addClass("selected-parent");
            }
        }


    }

    /* fixes issue with dropdowns being rendered as input boxes on Galaxy S3 and other older android browsers */
    function makeDropDownsNiceForOlderDroids(){
          var nua = navigator.userAgent
          var isAndroid = (nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf('Android ') > -1 && nua.indexOf('AppleWebKit') > -1 && nua.indexOf('Chrome') === -1)
          if (isAndroid) {
            $('select.form-control').removeClass('form-control').css('width', '100%')
          }
    }


    var cardTemplate = Oyster.templates.myOysterCards;

    init();


}());

function passwordChange(){
        $("#new_password").bind("blur", function() {
            var here = $(this), text = here.val(),id = here.attr("id");
            here.next('span[class^="password"]').remove();
            if (text.length == 0) return;
            var options = {
                'valid': $("<span class=\"passwordvalid\" style=\"display: block; \"><img alt=\"\" src=\"/tfl-oyster/images/tick.gif\" />New password valid " + "</span>"),
                'invalid': $("<span class=\"passwordinvalid\" style=\"display: block; \"><img alt=\"\" src=\"/tfl-oyster/images/cross.gif\" />New password invalid " + "</span>")
            };
            switch (id) {
                case "new_password":
                    here.after(options[/^(?=\S{8,}$)((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/.test(text) && text.length >= 8 ? 'valid' : 'invalid']);
                    break;
            }
        });
    }

    function passwordCheck(){
        $("#username,#password").bind("blur", function() {
            var here = $(this), text = here.val(), id = here.attr("id");
            here.next('span[class^="password"]').remove();
            if (text.length == 0) return;
            var options = {
                'valid': $("<span class=\"passwordvalid\" style=\"display: block; \"><img alt=\"\" src=\"/tfl-oyster/images/tick.gif\" />Valid " + id + "</span>"),
                'invalid': $("<span class=\"passwordinvalid\" style=\"display: block; \"><img alt=\"\" src=\"/tfl-oyster/images/cross.gif\" />Invalid " + id + "</span>")
            };

            switch (id) {

                case "username":
                    here.after(options[/^[a-zA-Z0-9\_]{1,}$/.test(text) ? 'valid' : 'invalid']);
                    break;
                case "password":
                    here.after(options[/^(?=\S{8,}$)((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/.test(text) && text.length >= 8 ? 'valid' : 'invalid']);
                    break;
            }
         });
    }
    function pinChange(){
        $(".currentPin #pin").hide();
        $("#linkShowHidePin").text("Show current PIN");

        $("#new_password").bind("blur", function() {
            var here = $(this), text = here.val(),id = here.attr("id");
            here.next('span[class^="password"]').remove();

            if (text.length == 0) return;

            var options = {
                    'valid': $("<span class=\"passwordvalid\" style=\"display: block; \"><img alt=\"\" src=\"/tfl-oyster/images/tick.gif\" />New telephone PIN valid " + "</span>"),
                    'invalid': $("<span class=\"passwordinvalid\" style=\"display: block; \"><img alt=\"\" src=\"/tfl-oyster/images/cross.gif\" />New telephone PIN invalid " + "</span>")
                };
            switch (id) {
                case "new_password":
                    here.after(options[/[0-9]{6}/.test(text) && text.length == 6 ? 'valid' : 'invalid']);
                    break;
            }
        });

        $("#linkShowHidePin").click(function () {
            if($("#linkShowHidePin").text() == "Show current PIN"){
                $(".currentPin #pinMask").hide();
                $(".currentPin #pin").show();
                $("#linkShowHidePin").text("Hide current PIN");
                $(".currentPin #pin").fadeOut(10000, function() {
                    $(".currentPin #pinMask").fadeIn(100);
                    $("#linkShowHidePin").text("Show current PIN");
                });
            }
            else{
                $(".currentPin #pin").hide();
                $(".currentPin #pinMask").show();
                $("#linkShowHidePin").text("Show current PIN");
            }
        });
    }