(function() {
    'use strict';
    /*jshint devel:true */


    /* Form
    Display select-optional based on value of select-controller
    */
    function setupSelectController() {
        $('.select-controller').on('change', function(e) {
            var elem = $(e.currentTarget);
            var fieldsets = elem.parents('.select-container').find('.select-optional');
            fieldsets.hide();
            var selectedID = elem.val();
            $(selectedID).show();
            //$('#errormessage-container').hide();
        });
    }

    function setInputInvalid(element) {
        element
            .attr({
                'aria-invalid': 'true'
            });
            //.parent('.form-group').addClass('has-error');
    }

    function clearInputInvalid(element) {
        element
            .attr({
                'aria-invalid': 'false'
            });
    }

    function setupAriaOnRequiredInputs() {
        var requiredFields = $(".required").next(".form-control");
        if (requiredFields.length <= 0) {
            return;
        }
        requiredFields.attr({
            'aria-required': 'true'
        });
        requiredFields.on('blur change', function() {
            var _self = $(this);
            if (!_self.val()) {
                setInputInvalid(_self);
            } else {
                clearInputInvalid(_self);
            }

        });
    }



    function toggleCollapseClasses(e) {
        e.stopPropagation();
        toggleSelectedClass(e);
        var elem = e.currentTarget.id;
        var class1 = e.data.class1;
        var class2 = e.data.class2;
        $('[data-target="#' + elem + '"]').find('span[class*="' + class1 + '"],span[class*="' + class2 + '"]')
            .toggleClass(class1)
            .toggleClass(class2);
        //if (elem === "")
    }

    function toggleSelectedClass(e) {
        var elem = e.currentTarget.id;
        $('[data-target="#' + elem + '"]').toggleClass('selected');
    }

    function setUpDateFields() {
        $('input.hasDatepicker').datepicker({
            format: 'dd/mm/yyyy',
            startDate: '+3d',
            endDate: '+1m',
            autoclose: true,
            weekStart: 1
        });
    }

    function init() {

        /* initialise the popover state */
        $('[data-toggle="popover"]').popover();
        /*set initial state of  + - icons*/
        $('.collapse.in')
            .prev('button.list-group-item')
            .addClass('selected')
            .find('.font-plus')
            .removeClass('font-plus')
            .addClass('font-minus');
        setupSelectController();
        setUpDateFields();
        setUpFormText();
    }

    function setUpFormText() {
        var isFirst = true;
        $("#page-content form").each(function(){
            if ($(this).find('input[type="submit"].btn-primary, button[type="submit"].btn-primary').length > 0 && $(this).find("label.required").length > 0){
                if (isFirst || $(this).parent("select-optional")){
                    isFirst = false;
                    $(this).prepend("<p class='small help-block'>All fields marked with an asterisk (*) are mandatory</p>");
                }
                else{
                    $(this).prepend("<p class='small help-block' style='visibility:hidden'>All fields marked with an asterisk (*) are mandatory</p>");
                }
            }
        });

    }

    function setManinnavBorder(e) {
        if ($(e.data.parent).find('.collapse.in').length <= 0) {
            $('#mainnav').removeClass('expanded');
        } else {
            $('#mainnav').addClass('expanded');
        }

    }

    function cookiePolicy(){
        var cookiePolicy = $.cookie("oysterCookiePolicy");
        if (typeof cookiePolicy == 'undefined' || $.cookie("oysterCookiePolicy") == 1){
            $("#cookie-policy-notice").show();
            $("#cookie-policy-button a").on('click', function(){
                 var expire = new Date();
                 expire.setTime(expire.getTime() + 3600000*24*730);
                $.cookie("oysterCookiePolicy", 2, { expires: expire });
                $("#cookie-policy-notice").hide();
            });
        }
    }

    $(document).ready(function() {
        setupAriaOnRequiredInputs();

        /* CHnage icon to minus or plus depending on state */
        $('#main-content .collapse')
            .on('show.bs.collapse', {
                class1: 'font-plus',
                class2: 'font-minus',
                parent: '#main-content'
            }, toggleCollapseClasses)
            .on('hidden.bs.collapse', {
                class1: 'font-plus',
                class2: 'font-minus',
                parent: '#main-content'
            }, toggleCollapseClasses);

        /* CHnage icon to minus or plus depending on state */
        $('#mainnav .collapse')
            .collapse({
                toggle: false
            })
            .on('show.bs.collapse', {
                class1: 'font-small-up-arrow',
                class2: 'font-small-down-arrow',
                parent: '#mainnav'
            }, toggleCollapseClasses)
            .on('shown.bs.collapse', {
                parent: '#mainnav'
            }, setManinnavBorder)
            .on('hidden.bs.collapse', {
                parent: '#mainnav'
            }, setManinnavBorder)
            .on('hide.bs.collapse', {
                class1: 'font-small-up-arrow',
                class2: 'font-small-down-arrow',
                parent: '#mainnav'
            }, toggleCollapseClasses);


        $('#nav-toggle-search').on('click', function() {
            $('#more-menu').collapse('hide');
            $('#navbar').collapse('hide');
            $('#mainnav').addClass('expanded');
        });

        $('#nav-toggle-menu').on('click', function() {
            $('#searchform').collapse('hide');
            $('#more-menu').collapse('hide');
            $('#mainnav').addClass('expanded');
        });

        $('#nav-toggle-more').on('click', function() {
            $('#searchform').collapse('hide');
            $('#mainnav').addClass('expanded');
            if ($(document).width() <= '768') {
                return;
            }
            if ($(this).hasClass('collapsed')) {
                $('#navbar').collapse('show');
            } else {
                $('#navbar').collapse('hide');
            }
        });

        init();
        cookiePolicy();

    });
}());
(function(tfl) {
    "use strict";
    tfl.dictionary = {
        MoreOptions: "Travel options & accessibility",
        LessOptions: "Fewer options",
        //CustomisedOptions: $.cookie("jp-pref") !== null ? " (customised)" : "",
        ShowDetailedView: "View Details",
        HideDetailedView: "Hide Details",
        ShowAllStops: "View all stops",
        HideAllStops: "Hide all stops",
        ShowTextInstructions: "View text directions",
        HideTextInstructions: "Hide text directions",
        NowText: "Now",
        TodayText: "Today",
        RemoveContentClass: ".remove-content",
        DisabledRemoveContentClass: "disable-removable-content",
        JpTypeCycling: "cycling",
        JpTypeWalking: "walking",
        FirstServiceText: "First service",
        LastServiceText: "Last service",
        CurrentLocationText: "Current location ",
        MonthNames: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        formClasses: {
            formControlWrapClass: "form-control-wrapper",
            formControlClass: "form-control",
            validationWrap: "form-field-error",
            validationMessageWrap: "field-validation-wrap",
            validationMessage: "field-validation-error",
            textAreaCounterClass: "text-area-counter",
            textAreaWithCounterClass: "text-area-with-counter",
            responsiveFormClass: "responsive-form",
            mandatoryFieldLabel: "mandatory-field-label"
        },
        hiddenElemClass: "hidden",
        browserClasses: {
            lessThanIe8: "lt-ie8",
            lessThanIe9: "lt-ie9",
            lessThanIe10: "lt-ie10",
            isSafari: "is-safari"
        },
        chargingZones: {
            CC: "congestioncharge",
            LEZ: "lowemission"
        },
        chargingZonesDisplayText: {
            congestioncharge: "Congestion Charge",
            lowemission: "Low Emission"
        }
    };
    console.log("tfl.dictionary: loaded");
})(window.tfl = window.tfl || {});

(function(o) {
    "use strict";
    console.log("tfl.listToDropdown: loaded");
    var throttle, $throttleTarget;
    o.init = function() {
        console.log("tfl.listToDropdown: initialised");
        o.buildHtmlDropdown();
        o.dropDownMouseFunctionality();
        o.hideShowList();
        o.datePickerDropdownBuilding();
    };
    o.buildHtmlDropdown = function() {
        $(".links-list").each(function() {
            var linkList = $(this);
            linkList.addClass("hidden");
            var dropDownTarget = linkList.data("dropdown-target");
            var dropDownLabel = linkList.data("dropdown-label");
            var selectedItem = linkList.data("selected-item-id");
            var dropdownStyling = linkList.data("dropdown-option");
            var datesClass = "";
            if (dropDownTarget === "date-dropdown-placeholder") {
                datesClass = "datepicker-dropdown";
                linkList.addClass(datesClass);
            }
            linkList.find('li[data-item-id="' + selectedItem + '"]').hide();
            linkList.wrap('<div class="for-dropdown" />');
            if (linkList.attr("id") === "gla-list") {
                linkList.parent().prepend('<div role="button" tabindex="0"  class="dropdown-button ' + dropdownStyling + " dropdown-closed " + datesClass + '"><img src="https://tfl.gov.uk/static/cms/images/MoL.png" alt="Mayor of London"><span class="mol-gla"><span class="font-small-up-arrow pull-right" aria-hidden="true"></span>GLA</span></div>');
            } else {
                linkList.parent().prepend('<div tabindex="0" role="button" ' + 'aria-label="' + dropDownLabel + '" ' + 'class="dropdown-button ' + dropdownStyling + " dropdown-closed " + datesClass + '" >' + selectedItem + "</div>");
            }
            $("." + dropDownTarget).append(linkList.parent());
            if (linkList.attr("id") !== "gla-list" || !linkList.attr("id")) {
                $(linkList).find("li:visible:first").css("border-top", "solid 1px #cacaca");
            }
            linkList.siblings(".dropdown-button").click(function() {
                o.hideShowList(linkList.siblings(".dropdown-button"));
            }).keypress(function(e) {
                if (e.which === 13) {
                    $(this).click();
                }
            });
            var currentHeight = 0, positionDropdown;
            if (linkList.hasClass("modes-list")) {
                var thisList = $(this);
                window.onresize = function() {
                    clearTimeout(positionDropdown);
                    positionDropdown = setTimeout(resizedw, 100);
                };
            }
            function resizedw() {
                var headerHeight, currentHeight;
                headerHeight = thisList.prev().height();
                if (currentHeight !== headerHeight) {
                    thisList.css("top", headerHeight + 14);
                    currentHeight = headerHeight;
                }
            }
        });
    };
    o.hideShowList = function(el) {
        if ($(el).hasClass("dropdown-closed")) {
            clearTimeout(throttle);
            throttle = 0;
            $throttleTarget = undefined;
            $('ul.links-list:not(".hidden")').addClass("hidden").prev(".dropdown-button").addClass("dropdown-closed");
        }
        $(el).next("ul.links-list").toggleClass("hidden");
        $("ul.links-list li:visible:first").css("border-top-width", "1px");
        $(el).toggleClass("dropdown-closed");
        clearTimeout(throttle);
        throttle = 0;
        $throttleTarget = undefined;
    };
    o.dropDownMouseFunctionality = function() {
        $(".for-dropdown").on("mouseleave", function() {
            var $this = $(this);
            $throttleTarget = $this;
            throttle = setTimeout(function() {
                if (!$this.children(".dropdown-button").hasClass("dropdown-closed")) {
                    o.hideShowList($this.children(".dropdown-button"));
                }
            }, 500);
        }).on("mouseenter", function() {
            if (throttle && $(this).is($throttleTarget)) {
                clearTimeout(throttle);
                throttle = 0;
                $throttleTarget = undefined;
            }
        });
    };
    o.datePickerDropdownBuilding = function() {
        $(".advance-month-container").parent().addClass("no-hover");
    };
    o.init();
})(window.tfl.listToDropdown = window.tfl.listToDropdown || {});